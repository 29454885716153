export default {
  // 角色权限
  // getPermissions: ['get', '/user_management/authorization/permission'], // 获取所有角色对应的名称（获取权限树）
  getPermissions: ['get', '/micro_structure/permission_trees'],
  // postRoles: ['post', '/user_management/authorization/role'], // // 创建角色（新增角色）
  postRoles: ['post', '/micro_structure/role'],
  // getRoles: ['get', '/user_management/authorization/role'], // 获取所有角色对应的名称（获取角色列表）
  getRoles: ['get', '/micro_structure/role/list'],
  // updateRoles: ['put', '/user_management/authorization/role'], // 编辑角色
  updateRoles: ['put', '/micro_structure/role/:role_id'],
  // deleteRoles: ['delete', '/user_management/authorization/role'], // 删除角色
  deleteRoles: ['delete', '/micro_structure/role/:role_id'],
  // getRolePermission: ['get', '/user_management/authorization/role_permission'], // 角色的权限
  getRolePermission: ['get', '/micro_structure/role/:role_id/permissions'],
  // postRolesPermissions: ['put', '/user_management/role'], // 修改角色
  postRolesPermissions: ['post', '/micro_structure/role/:role_id/permissions'], // 修改角色对应的权限？？？

  // 组织架构
  // getOrganization: ['get', '/user_management/organization'], // 获取组织架构
  getOrganization: ['get', '/micro_structure/employee/list'],
  // postEmployee: ['post', '/user_management/organization/employee'], // 新增员工
  postEmployee: ['post', '/micro_structure/employee'],
  // updateEmployee: ['put', '/user_management/organization/employee'], // 编辑员工
  updateEmployee: ['put', '/micro_structure/employee/:employee_id'],
  // stopEmployee: ['delete', '/user_management/organization/employee'], // 停用员工
  stopEmployee: ['delete', '/micro_structure/employee/:employee_id'],
  // startEmployee: ['patch', '/user_management/organization/employee'], // 启用员工
  startEmployee: ['patch', '/micro_structure/employee/:employee_id'],
  // updateRole: ['put', '/user_management/role'] // 修改角色
  updateRole: ['post', '/micro_structure/employee/:employee_id/role'],
  getEmployeePermission: ['get', '/micro_structure/employee/permissions']
}
