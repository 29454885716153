export default [{ // 门店管理 - 组织架构
  path: '/operate/framework',
  component: resolve => import(/* webpackChunkName: "operateManage" */ '../FrameWork.vue').then(resolve),
  meta: {
    title: '组织架构',
    nav: '/operate/framework',
  },
}, { // 门店管理 - 角色权限
  path: '/operate/rolepermission',
  component: resolve => import(/* webpackChunkName: "operateManage" */ '../RolePermission.vue').then(resolve),
  meta: {
    title: '角色权限',
    nav: '/operate/rolepermission',
  },
}
];
